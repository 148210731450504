.boxContainer {
  padding: 20px 0;
  text-decoration: none;
  width: 400px;
  height: 300px;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  margin: 20px;
  border-radius: 16px;
  font-size: 21px;
  color: #5273e6;
  font-family: "Roboto Geo Caps";
  display: inline-flex;
  position: relative;
  vertical-align: middle;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-weight: bold;
  transition: box-shadow 0.2s;
}
.boxContainer:hover {
  box-shadow: 0 0 20px 0 rgba(82, 115, 230, 0.6);
}
.boxContainer .sendCognitiveAssignmentBuld {
  background-repeat: no-repeat;
  background-size: 140px 140px;
  background-position: center;
  width: 150px;
  height: 150px;
}
@media screen and (max-width: 1400px) {
  .boxContainer {
    width: 300px;
    height: 250px;
    padding: 20px 20px 30px;
    margin: 20px;
    font-size: 18px;
  }
  .boxContainer .sendCognitiveAssignmentBuld {
    width: 120px;
    height: 120px;
  }
}
@media screen and (max-width: 400px) {
  .boxContainer {
    margin: 10px;
  }
}
.archiveButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.boxContainer_title {
  font-family: "Roboto Geo Caps";
  margin-top: 10px;
}
.assignmentsArchiveOpen,
.assignmentsArchiveClose,
.emptyAssignments {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5273e6;
  font-family: "Roboto Geo Caps";
  font-weight: bold;
  cursor: pointer;
  margin-top: 50px;
}
.emptyAssignments {
  font-family: "Roboto Geo Nus";
  margin-top: 20px;
  color: #58607c;
}
.assignmentsArchiveClose {
  color: #58607c;
}
.assignmentsContainer {
  width: 100%;
  padding: 0 100px;
}
@media screen and (max-width: 1000px) {
  .assignmentsContainer {
    padding: 0 20px;
  }
}
.allAssignments {
  margin-top: 100px;
}
.notification {
  z-index: 10;
  background: white;
  position: relative;
  width: 900px;
  margin: 0 auto;
  max-width: 95%;
  border-radius: 15px;
  padding: 30px 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 20px;
  text-align: center;
  font-size: 1.2em;
  font-family: "Roboto Geo Nus";
}
@media screen and (max-width: 600px) {
  .notification {
    margin-top: 25px;
  }
}
