.proposalContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #626a84;
	font-family: "Roboto Geo MT Bold";
	.heading {
		font-size: 22px;
		line-height: 27px;
		font-weight: bold;
	}
	.proposalText {
		font-family: "Proposal Geo Nus";
		font-size: 16px;
		line-height: 22px;
		text-align: justify;
		width: 86%;
		margin-top: 5px;
	}
	.choose {
		font-family: "Roboto Geo Caps";
		font-size: 20px;
		line-height: 27px;
		margin-bottom: 10px;
	}
	.topicsContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		.topic {
			cursor: pointer;
			height: 80px;
			padding: 0;
			display: flex;
			align-items: center;
			width: 86%;
			margin: 15px 0px 15px 0px;
			border-radius: 15px;
			box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
			background-color: var(--white);
			.checkRect {
				width: 30px;
				height: 30px;
				margin: 0px 30px 0px 30px;
				border-radius: 8px;
				border: solid 1px #626a84;
				cursor: pointer;
				background-color: var(--white);
			}
			.topicName {
				display: flex;
				color: #5273e6;
				text-align: left;
				font-size: 20px;
				max-width: 80%;
			}
		}
	}
	.sendButton {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 300px;
		height: 55px;
		font-size: 20px;
		font-weight: bold;
		border-radius: 15px;
		background-color: #5273e6;
		color: white;
		margin-top: 30px;
	}
}
