.indHour {
	float: left;
}

.indMinute {
	float: left;
	margin-left: 10px;
}

.label {
	text-align: center;
	font-family: "Roboto Geo Nus";
	color: #aeb5c9;
	font-size: 16px;
	margin-bottom: 5px;
	pointer-events: none;
	display: block;
}

.disabledSelect {
	position: relative;
	width: 70px;
	height: 50px;
	border-radius: 15px;
	// border: solid 1px #aeb5c9;
	border: none;
	background-color: #eff1f7;
	color: #626a84;
}

.select {
	position: relative;
	width: 70px;
	height: 50px;
	border-radius: 15px;
	// border: solid 1px #aeb5c9;
	border: none;
	background-color: #eff1f7;
	color: #626a84;
	font-family: "Roboto Geo MT Bold";
	font-size: 18px;
	appearance: none;
	text-align: center !important;
	outline: none;
	// text-align-last:center;
	padding-left: 24px;

	&:hover {
		box-shadow: 0px 0px 4px 2px rgb(102, 181, 255);
		cursor: pointer;
		// border: 1px solid #eff1f7;
		border: none;
	}

	option {
		background-color: white;
		&.hidden {
			display: none;
		}
	}
}
